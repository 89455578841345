import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        height: theme.spacing(5),
        border: 'solid 1px #dbe0e4',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: '#747b80',
    },
});

@withStyles(styles)
class RowCell extends Component {
    render() {
        const { column, classes, children, ...restProps } = this.props;
        return (
            <TableCell
                align={column.numeric ? 'right' : 'left'}
                className={classes.root}
                {...restProps}
            >
                {children}
            </TableCell>
        );
    }
}

export default RowCell
import { BLOCK, COL_PARSERS } from '../conf/constants';

import startOfMonth from 'date-fns/startOfMonth';
import startOfYear from 'date-fns/startOfYear';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import endOfYear from 'date-fns/endOfYear';
import differenceInDays from 'date-fns/differenceInDays'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';

export function getNetworksFromPermTree(permTree) {
    const networks = new Set();
    if (permTree.hasOwnProperty('data')
        && permTree.data.hasOwnProperty('groups')) {
        for (const group of permTree.data.groups) {
            if (group.hasOwnProperty('policies')) {
                for (const policy of group.policies) {
                    if (policy.hasOwnProperty('networks')) {
                        for (const network of policy.networks) {
                            networks.add(network);
                        }
                    }
                }
            }
        }
    }
    return Array.from(networks).sort();
}

export function getSubStationfromNetwork(networkData) {
    if (networkData.message === "OK") {
        if (networkData.hasOwnProperty('data')) {
            return networkData.data.resource_list;
        }
    }
    return []
}

export function blockID(resource_id, year, month, resource_type = 'network', sub = "") {
    let k = `${resource_type}$${sub}$${resource_id}`;
    if (year) {
        k += `$${year}`
        if (month) {
            k += `$${month}`
        }
    }
    return k;
}

export function isValuePresent(value) {
    return value !== undefined &&
        value !== null &&
        value !== '' &&
        value !== 0 &&
        value !== 'NaN' &&
        !isNaN(value);
}

export function isVal(value) {
    return value !== undefined && value !== null && value !== '' && value !== 'NaN' && !isNaN(value);
}

export function getDefaultAnalysisYear() {
    //new Date().getFullYear(); once data sets are regularly updated.
    return 2017;
}

export function getDefaultAnalysisMonth() {
    return null;
}

export function isArrayEqual(a, b) {
    if (a.length !== b.length)
        return false;
    else {
        // comparing each element of array 
        for (var i = 0; i < a.length; i++)
            if (a[i] !== b[i])
                return false;
        return true;
    }
}

export function dateFromYM(bound, year, month) {
    let res = null;
    if (bound === 'start') {
        if (month) {
            res = startOfMonth(new Date(year, month, 15))
        } else {
            res = startOfYear(new Date(year, 4, 15))
        }
    } else if (bound === 'end') {
        if (month) {
            res = roundToNearestMinutes(endOfMonth(new Date(year, month, 15)))
        } else {
            res = roundToNearestMinutes(endOfYear(new Date(year, 4)))
        }
    }
    return res;
}

export function formatDateAPI(date) {
    return new Date(date).toISOString().slice(0, -5)
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function sortByIndex(index, direction = "asc", type = "number") {

    var ascending = direction === "asc";

    let options = {};
    options['numeric'] = type === "number" ? true : false;

    let cmpf = new Intl.Collator("sv", options).compare

    function sort(a, b) {
        let x = a[index], y = b[index];
        return ascending ? cmpf(x, y) : cmpf(y, x);
    }

    return sort;
}


export function splitDf(rdf, block_definitions) {
    /*
    split the received df into component blocks and transform/read them.
     */
    block_definitions = block_definitions || BLOCK;
    const mainColumns = rdf.columns;
    const mainRows = rdf.data;
    let blockNames = new Map();

    for (let colIdx = 0; colIdx < mainColumns.length; colIdx++) {
        for (let specName of Object.keys(block_definitions)) {
            const BLKSPEC = block_definitions[specName]
            let blkParts = BLKSPEC.parse_block_name(mainColumns[colIdx])
            if (blkParts && BLKSPEC.columns.hasOwnProperty(blkParts.col)) {
                let meta;
                const currentColMeta = {
                    col: blkParts.col,
                    idx: colIdx,
                    blk: blkParts.blk,
                    blkName: blkParts.blkname,
                    spec: BLKSPEC.columns[blkParts.col]
                }
                if (blockNames.has(blkParts.blkname)) {
                    meta = blockNames.get(blkParts.blkname);
                    meta.columns.push(currentColMeta)
                } else {
                    let indexCol = mainColumns.indexOf(BLKSPEC.index)
                    meta = {
                        blk: block_definitions[blkParts.blk],
                        name: BLKSPEC.name,
                        columns: [
                            {
                                col: BLKSPEC.index,
                                idx: indexCol,
                                blkName: blkParts.blkname,
                                blk: blkParts.blk,
                                spec: BLKSPEC.columns[BLKSPEC.index]
                            },
                            currentColMeta]
                    }
                }
                blockNames.set(blkParts.blkname, meta)
            }
        }
    }
    blockNames = Array.from(blockNames)
    let allBlocks = [];
    for (let [blkName, blkMeta] of blockNames) {
        const indexColumnName = blkMeta.blk.index;
        let cblk = {
            columns: {},
            data: [],
            idx: indexColumnName,
            name: blkName,
            blockname: blkMeta.name,
            idxMap: new Map()
        };
        for (let i = 0; i < blkMeta.columns.length; i++) {
            let m = blkMeta.columns[i];
            cblk.columns[m.col] = { idx: i, spec: m.spec }
        }
        for (let rowIdx = 0; rowIdx < mainRows.length; rowIdx++) {
            let row = mainRows[rowIdx]
            cblk.data.push(blkMeta.columns.map(m => {
                let val = COL_PARSERS[m.spec.type](row[m.idx]);
                if (m.col === indexColumnName) {
                    cblk.idxMap.set(val, rowIdx)
                }
                return val
            }
            ))
        }
        allBlocks.push([cblk.name, cblk]);
    }
    return allBlocks;
}

export function sortRows(rows, property, direction, deviation = false, include_nulls = false) {
    /* rows format should be array of json */
    if (!include_nulls) {
        rows = rows.filter((r) => isVal(r[property]))
    }
    return rows.sort((a, b) => {
        let x = a[property], y = b[property];
        if (x === y) {
            return 0
        } else if (!isVal(x)) {
            return direction === "asc" ? -1 : 1;
        } else if (!isVal(y)) {
            return direction === "asc" ? 1 : -1;
        }
        if (deviation) {
            x = Math.abs(x)
            y = Math.abs(y)
        }
        if (direction === "asc") {
            return x < y ? -1 : 1;
        } else {
            return x < y ? 1 : -1;
        }
    })
}

export function deviation_pct(row, propA, propB) {
    if (isVal(row[propA]) && isVal(row[propB])) {
        return ((row[propB] - row[propA]) / row[propB]) * 100
    } else {
        return undefined
    }
}

export function getActiveYM(currentDate) {
    currentDate = currentDate || new Date(2018, 0, 8);
    const daysAfterMonthStart = differenceInDays(currentDate, startOfMonth(currentDate))
    let prevMonth = -1;
    if (daysAfterMonthStart <= 5) {
        prevMonth = -2
    }
    const activeDate = addMonths(currentDate, prevMonth)
    return [activeDate.getFullYear(), activeDate.getMonth()]
}
